import axios from 'axios';
import { getAccessToken } from './localStorage';

let API_URL = "https://api.uxtoggle.com/";

export const getHeaderInfo = async function () {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
};

export const getApi = async function (url, params) {
  try {
    const header = await getHeaderInfo();
    const resp = await axios.get(`${API_URL}${url}`, { ...header, params });
    return resp.data;
  } catch (err) {
    console.error("GET request failed:", err);
    throw err;
  }
};

export const postApi = async function (url, body) {
  try {
    const header = await getHeaderInfo();
    const resp = await axios.post(`${API_URL}${url}`, body, header);
    console.log(resp);
    return resp.data;
  } catch (err) {
    console.error("POST request failed:", err);
    throw err;
  }
};

export const patchApi = async function (url, body) {
  try {
    const header = await getHeaderInfo();
    const resp = await axios.patch(`${API_URL}${url}`, body, header);
    return resp.data;
  } catch (err) {
    console.error("PATCH request failed:", err);
    throw err;
  }
};

export const putApi = async function (url, body) {
  try {
    const header = await getHeaderInfo();
    const resp = await axios.put(`${API_URL}${url}`, body, header);
    return resp.data;
  } catch (err) {
    console.error("PUT request failed:", err);
    throw err;
  }
};

export const deleteApi = async function (url) {
  try {
    const header = await getHeaderInfo();
    const resp = await axios.delete(`${API_URL}${url}`, header);
    return resp.data;
  } catch (err) {
    console.error("DELETE request failed:", err);
    throw err;
  }
};
