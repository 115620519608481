import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useNavigate } from 'react-router-dom';


function Home() {


  return (
    <div className="App">
      <h1 className='home-title'>Review your UX UI Design case study for your next job!</h1>
      <p className='text-xl home-desc'>Are you struggling to present your case study for design job interviews? Submit your design case study and we will
        suggest feedback to improve your designs.</p>
      {/* <form className="input-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Submit any ONE case study link (Behance, Dribbble, Figma link)"
          value={caseStudyLink}
          onChange={(e) => setCaseStudyLink(e.target.value)}
        />
        {errors.caseStudyLink && <p className="error">*{errors.caseStudyLink}</p>}
        <div className="email-form" style={{ marginTop: "10px" }}>
          <input
            className='email-input'
            type="email"
            placeholder="Your Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">
            {!buttonLoader?
            "Pay & Submit →":
          <img src={loader} className='button-loader'/>}
           </button>
        </div>
        {errors.email && <p className="error">*{errors.email}</p>}
      </form> */}
      <Link to="/login" className='button mt-5'>Login to your account</Link>
    </div>
  );
}

export default Home;
