import React, { useState, useEffect } from 'react';
import '../App.css';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApi, postApi } from '../services/apiService';
import { api_constatnts } from '../config/api_constatnt';
import moment from 'moment';

export default function AddDesign() {
  const [caseStudyLink, setCaseStudyLink] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const apiUrl = process.env.API_URL || "https://api.uxtoggle.com";
  const razorpayKeyId = process.env.R_AZ_OR_P_AY_K_EYI_D || "rzp_test_NocyNVRYEO2kxf";
  const razorpayURL = process.env.RAZORPAY_URL || "https://checkout.razorpay.com/v1/checkout.js"
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const [currency, setCurrency] = useState('USD');

  async function getUserCountry() {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      return response.data;
    } catch (error) {
      console.error('Error fetching user location:', error);
      return null;
    }
  }

  useEffect(() => {
    async function fetchCountryAndSetCurrency() {
      const userCountry = await getUserCountry();
      if (userCountry) {
        setCurrency(userCountry.currency);
      }
    }
    fetchCountryAndSetCurrency();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!caseStudyLink) {
      newErrors.caseStudyLink = 'Case study link is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setErrors({});
    setButtonLoader(true);
    try {
      const response = await postApi(api_constatnts.addCaseStudy, {
        caseStudyUrl: caseStudyLink,
        currency,
        reviewTime:moment().add(7, 'days'),
        createdAt: new Date()
      });
      if (response.id) {
        handlePayment(response);
      } else {
        setButtonLoader(false);
        Swal.fire({
          icon: "error",
          text: response.message || "Failed to submit. Please try again.",
        });
      }
    } catch (error) {
      setButtonLoader(false);
      Swal.fire({
        icon: "error",
        text: "An error occurred. Please try again.",
      });
    }
  };


  const handlePayment = async (data) => {
    const res = await loadRazorpayScript();
    if (!res) {
      Swal.fire({
        icon: "question",
        text: "FRazorpay SDK failed to load. Are you online?",
      });
      return;
    }

    console.log(data);

    const options = {
      key: razorpayKeyId,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: 'Design Case Study Review',
      description: 'Submit your design case study for review',
      handler: function (response) {
        setButtonLoader(false);
        getSuccessResponse(data?.payment?._id)
        //  console.log("payment response",response);
      },
      prefill: {
        name: data?.user?.first_name,
        email: data?.user?.email,
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = razorpayURL;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
        setButtonLoader(false);
      };
      document.body.appendChild(script);
    });
  };

  async function getSuccessResponse(id) {
    try {
      const res = await getApi(api_constatnts.successResponse + id, {});
      if (res.result == "success") {
        navigate('/dashboard');
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: res.data.message,
        });
      }
    } catch (error) {
      console.error('Error fetching success response:', error);
    }
  }
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center mt-20">
      <div className='w-full' style={{ maxWidth: '600px' }}>
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md ">
          <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Add New Design</h1>
          {/* Design Name Field */}
          {/* <div className="mb-4">
            <label htmlFor="designName" className="block text-sm font-medium text-gray-700">
              Design Name
            </label>
            <input
              type="text"
              id="designName"
              name="designName"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
              placeholder="Enter design name"
              required
            />
          </div> */}

          {/* URL Field */}
          <div className="mb-4">
            <label htmlFor="caseStudyLink" className="block text-sm font-medium text-gray-700">
              Design URL
            </label>
            <input
              type="caseStudyLink"
              id="caseStudyLink"
              name="caseStudyLink"
              value={caseStudyLink}
              onChange={(e) => setCaseStudyLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
              placeholder="Enter design URL"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-black text-white font-semibold rounded-md shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-1">
            {
              buttonLoader ?
                <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg> :
                'Submit'
                }
          </button>
        </form>
      </div>
    </div>
  );
}
