import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import the hook for navigation
import { postApi } from '../services/apiService'; // Adjust path as needed
import { setFirbaseToken, setTokens } from '../services/localStorage'; // Adjust path as needed
import { api_constatnts } from '../config/api_constatnt';

export default function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  // Replace with actual Firebase auth ID parameter if available
  const firebaseAuthToken = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!firebaseAuthToken) {
      console.error('Firebase Auth ID is missing');
      return;
    }

    try {
      const response = await postApi(api_constatnts.signup, {
        firebase_auth_id: firebaseAuthToken.id,
        first_name: firstName,
        last_name: lastName,
        phone:phone
      });
      setTokens(response.token); 
      setFirbaseToken(firebaseAuthToken); 
      navigate('/dashboard');
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <div
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex">
      <div className="relative p-4 w-full max-w-md h-full md:h-auto bg-white rounded-lg" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
        <div className="p-5 py-10">
          <p className="mb-10 text-3xl font-semibold leading-5 text-slate-900 text-center">
            Signup
          </p>
          <form className="w-full" onSubmit={handleSubmit}>
            <label htmlFor="fname" className="block text-sm font-medium text-gray-700">
              First name
            </label>
            <input
              id="fname"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="block w-full rounded-lg border border-gray-200 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black mb-5 bg-white"
              placeholder="First name"
            />
            <label htmlFor="lname" className="block text-sm font-medium text-gray-700">
              Last name
            </label>
            <input
              id="lname"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="block w-full rounded-lg border border-gray-200 px-3 py-2 shadow-sm outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black mb-5 bg-white"
              placeholder="Last name"
            />
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone number
            </label>
            <input
              id="phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="block w-full rounded-lg border border-gray-200 px-3 py-2 outline-none placeholder:text-gray-400 focus:ring-2 focus:ring-black mb-5 bg-white"
              placeholder="Phone number"
            />
            <button
              type="submit"
              className="mt-5 inline-flex w-full items-center justify-center rounded-lg bg-black p-2 py-3 text-sm font-medium text-white outline-none focus:ring-2 focus:ring-black focus:ring-offset-1 disabled:bg-gray-400"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
