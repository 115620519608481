import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Success from './pages/success';
import AddDesign from './pages/add-design';
import Login from './pages/login';
import Signup from './pages/signup';
import Dashboard from './pages/dashboard';
import DesignList from './pages/designs-list';
import Profile from './pages/profile';
import DesignDetail from './pages/design-detail';

function App() {
  return (
    <Router>
      {/* Define all your routes in a single Routes component */}
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup/:id" element={<Signup />} />

        {/* Dashboard (protected routes) */}
        <Route element={<Dashboard />}>
          {/* These routes will render inside the Dashboard component */}
          <Route path="/dashboard" element={<DesignList />} />
          <Route path="/add-design" element={<AddDesign />} />
          <Route path="/design-detail/:id" element={<DesignDetail />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/success" element={<Success />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
