import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';

function Success() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/dashboard');
  };

  return (

    <div
      className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex">
      <div className="relative p-4 w-full max-w-md h-full md:h-auto bg-white rounded-lg" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
        <div className="p-5 py-10 text-center">

          <h1 className='text-3xl font-bold mb-1'>Thank You!</h1>
          <p className='mb-5'>Your payment has been received successfully.</p>
          <Link to="/dashboard" className='bg-blue-500 shadow-lg  text-white py-3 px-4 rounded'>To Dashboard</Link>
        </div>
      </div>
    </div>
  );
}

export default Success;
