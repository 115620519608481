import React, { useEffect, useState } from 'react';
import { api_constatnts } from '../config/api_constatnt';
import { getApi } from '../services/apiService';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import firma from '../../src/images/figma.png'

export default function DesignDetail() {
    const id = useParams();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getCaseStudy()
    }, []);

    async function getCaseStudy() {
        try {
            const res = await getApi(api_constatnts.caseStudy + id.id, {});
            setData(res.data)
            setLoader(false)
        } catch (error) {
            console.error('Error fetching success response:', error);
            setLoader(false)
        }
    }



    const firstLetterToUpper = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const calculateTimeAgo = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diff = now - date;
        const minutes = Math.floor(diff / (1000 * 60));
        if (minutes < 60) {
            return `${minutes} minutes ago`;
        }
        const hours = Math.floor(minutes / 60);
        return hours < 24 ? `${hours} hours ago` : `${Math.floor(hours / 24)} days ago`;
    };

    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">Design Details</h1>
            {
                !loader ?
                    <div className="bg-white rounded-lg shadow-md" style={{ maxWidth: '500px' }}>
                        <div className="bg-gray-800 flex flex-col sm:flex-row justify-between md:items-center p-6">
                            <div className="flex md:justify-between items-center gap-2">
                                <div className="bg-gray-200 rounded-full w-9 h-9 flex justify-center items-center font-bold text-md">
                                    {data.user.first_name?.slice(0, 2).toUpperCase()}
                                </div>
                                <div>
                                    <span className="text-xl text-white">{ data.user.first_name?firstLetterToUpper(data.user.first_name) +" "+data.user.last_name:""}</span>
                                    <p className="text-white text-xs text-right" style={{ fontWeight: 100 }}>
                                        {data.user.email}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="p-6">
                            <div className="space-y-4 mb-4" style={{ minHeight: '350px', overflow: 'auto' }}>
                                <div className="iframe-div mb-2 flex justify-center items-center " style={{ backgroundColor: 'rgb(243, 243, 243)' }}>
                                    {/* <iframe src={data.caseStudyUrl} title="Embedded Web Page"></iframe> */}
                                    <img src={firma}/>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-xs text-gray-500">{calculateTimeAgo(data.createdAt)}</p>
                                    <button
                                        type="button"
                                        className={`text-white font-medium rounded-lg text-sm px-5 py-1.5 w-fit ${data.payment.payment_status === 'paid' ? 'bg-green-700' : 'bg-red-600'
                                            }`}
                                    >
                                        {data.payment.payment_status === 'paid' ? 'Paid' : 'Not Paid'}
                                    </button>
                                </div>
                                    <a className="text-sm text-blue-600 hover:underline" href={data.caseStudyUrl} target="_blank" rel="noopener noreferrer">
                                        {data.caseStudyUrl}
                                    </a>

                                    <p className="text-xs text-gray-500 text-right">Review By: {moment(data.reviewTime).format('DD MMM YYYY')}</p>
                                    {/* <p className="text-xs text-gray-500">
                                        Response:{" "}
                                        <span className={data.commented ? 'text-green-600' : 'text-blue-600'}>
                                            {data.commented ? 'Responded' : 'Pending'}
                                        </span>
                                    </p> */}
                                {
                                    data?.commented ?
                                        <div className='p-3 rounded text-sm bg-gray-100 text-gray-600'>
                                            <p ><span className=" font-bold">Admin Comment: </span> {data.comment}</p>
                                            <p className="text-xs text-gray-500 text-right mt-2">
                                                {new Date(data.commentTime).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
                                            </p>
                                        </div> : ""
                                }

                            </div>
                        </div>
                    </div>
                    : ""
            }


        </div>
    );
}
