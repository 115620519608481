// ------ firebase token
export const setFirbaseToken = (firbase_token) => {
  localStorage.setItem('firbase_token', firbase_token);
};

export const removeFirbaseToken = () => {
  localStorage.removeItem('firbase_token');
};

export const getAccessFirbaseToken = () => {
  return localStorage.getItem('firbase_token');
};

// ----- serer token
export const setTokens = (token) => {
  localStorage.setItem('uxtoggle_token', token);
};

export const removeTokens = () => {
  localStorage.removeItem('uxtoggle_token');
};

export const getAccessToken = () => {
  return localStorage.getItem('uxtoggle_token');
};


// ------ user
export const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const getUser = () => {
  let user=localStorage.getItem('user')
  return JSON.parse(user);
};

export const removeUser = () => {
  localStorage.removeItem('user');
};
