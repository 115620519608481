import React from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"; // Import the hook for navigation

import { useNavigate } from 'react-router-dom';
import { setFirbaseToken, setTokens } from '../services/localStorage';
import { post, postApi } from '../services/apiService';
import { firebaseConfig } from '../config/env';
import { api_constatnts } from '../config/api_constatnt';


export default function Login() {


  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();


  async function signInWithGoogle() {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      login(user.accessToken)
      console.log(user);
    } catch (error) {
      console.log('Error during Google Sign-In:', error);
    }
  };

  async function login(accessToken) {
    try {
      const response = await postApi(api_constatnts.login,{firebase_auth_id:accessToken});
      setFirbaseToken(accessToken);
      setTokens(response.token);
      navigate('/dashboard');
      return response;
    } catch (error) {
      console.error('Error fetching profile:', error);
      navigate(`/signup/${accessToken}`);
      return null;
    }
  }
  return (
    <div id="login-popup" tabIndex="-1" className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex">
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow">
          <div className="p-5">
            <h3 className="text-2xl mb-0.5 font-medium"></h3>
            <p className="mb-4 text-sm font-normal text-gray-800"></p>

            <div className="text-center">
              <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                Login to your account
              </p>
              <p className="mt-2 text-sm leading-4 text-slate-600">
                You must be logged in to perform this action.
              </p>
            </div>

            <div className="mt-7 flex flex-col gap-2 border rounded-lg mx-4 hover:shadow-lg border border-slate-300 ">
              <button onClick={signInWithGoogle}
                className="inline-flex h-10 w-full items-center justify-center gap-2 rounded-lg border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60">
                <img src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google" className="h-[18px] w-[18px]" />
                Continue with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


