export const env = {
  development: {
    API_URL: 'https://api.uxtoggle.com/',
  },
  production: {
    API_URL: 'https://api.uxtoggle.com/',
  },
  test: {
    API_URL: '"http://localhost:8080"',
  },
};

export const firebaseConfig = {
  apiKey: "AIzaSyDMlhuCuq62TdeznGaq_F-kzJ5c9MuJ0Ts",
  authDomain: "uxtoggle-9531e.firebaseapp.com",
  projectId: "uxtoggle-9531e",
  storageBucket: "uxtoggle-9531e.appspot.com",
  messagingSenderId: "143441581885",
  appId: "1:143441581885:web:6371151bfe91ff32a6d754",
  measurementId: "G-0XNXBSRE58"
};