import React, { useEffect, useState, useRef } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { getApi } from '../services/apiService';
import { api_constatnts } from '../config/api_constatnt';
import { removeTokens, removeUser } from '../services/localStorage';

export default function Dashboard() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    removeTokens();
    removeUser();
    navigate('/');
  };

  useEffect(() => {
    async function fetchProfile() {
      try {
        const data = await getApi(api_constatnts.profile); // Adjust endpoint as per your backend
        setProfileData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to load profile data');
        setLoading(false);
      }
    }

    fetchProfile();
  }, []);

  // Add a useEffect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navigation Bar */}
      <nav className="bg-black p-4 py-5">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-white text-xl font-semibold">
            <Link to="/dashboard">
              UX Toggle
            </Link>
          </div>

          {/* Navigation Links */}
          <div className="space-x-10 flex items-center">
            <Link to="/dashboard" className="text-white hover:underline">
              Home
            </Link>
            <div className="text-white hover:underline flex items-center gap-2">
              <div className='text-right' style={{ lineHeight: "14px" }}>
                {loading ? (
                  <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                  </svg>
                ) : (
                  <div className="relative inline-block text-left" ref={dropdownRef}>
                    <div className="flex gap-2 items-center cursor-pointer" onClick={toggleDropdown}>
                      {profileData.first_name
                        ? `${profileData.first_name} ${profileData.last_name}`
                        : ''}
                      <div className="w-9 h-9 flex items-center justify-center font-bold rounded-full bg-white text-black text-lg" style={{ paddingBottom: "3px" }}>
                        {profileData.first_name?.charAt(0)}
                      </div>
                    </div>

                    {/* Dropdown menu */}
                    {isOpen && (
                      <div className="absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <Link to="/profile" className=" p-4 text-sm text-gray-700 hover:bg-gray-200 flex gap-2" role="menuitem">
                            <div className="w-12 h-12 flex items-center justify-center font-bold rounded-full bg-gray-300 text-black text-lg" style={{ paddingBottom: "3px" }}>
                              {profileData.first_name?.charAt(0)}
                            </div>
                            <div>
                              <p className="font-bold text-lg">
                                {profileData.first_name
                                  ? `${profileData.first_name} ${profileData.last_name}`
                                  : ''}
                              </p>
                              <p>
                                {profileData.email ? `${profileData.email}` : ''}
                              </p>
                            </div>
                          </Link>
                          <a
                            href="#contact-support"
                            className="block p-4 text-sm text-gray-700 hover:bg-gray-200"
                            role="menuitem"
                          >
                            Contact & Support
                          </a>
                          <button
                            onClick={handleLogout}
                            className="block w-full text-left px-4 py-4 text-sm text-gray-700 hover:bg-gray-200"
                            role="menuitem"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="flex justify-center">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
